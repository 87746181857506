<template>
   <transition name="scrollBtn">
    <div v-show="buttonActive" id="scroll-link-btn">
      <img class="scroll-link-btn-image"
      :src="image"
      @mouseover="imgHover()"
      @mouseleave="imgLeave()"
      @click="backToTop"
    >
    </div>
     </transition>
</template>

<style lang="scss">
.scrollBtn-enter-active,
.scrollBtn-leave-active {
  transition: opacity 0.7s;
}
.scrollBtn-enter,
.scrollBtn-leave-to {
  opacity: 0;
}
.scroll-link-btn-image {
    cursor:pointer;
}
</style>

<script>
export default ({
  name: 'ScrollLink',
  data() {
    return {
      buttonActive: false,
      scroll: 0,
      image: require('@/assets/img/btn.png')
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollWindow)
  },
  methods: {
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    scrollWindow() {
      const top = 900
      this.scroll = window.scrollY
      if (top <= this.scroll) {
        this.buttonActive = true
      } else {
        this.buttonActive = false
      }
    },
    imgHover(){
      this.image = require('@/assets/img/btn_hover.png');
    },
   imgLeave(){
      this.image = require('@/assets/img/btn.png');
    }
  }
})
</script>